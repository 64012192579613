<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <!--        <v-img
                  :src="require('../assets/logo.svg')"
                  class="my-3"
                  contain
                  height="200"
                />-->
        <v-card flat>
          <v-img
              :src="require('../assets/optimized/pots.webp')"
              class="white--text align-end"
              contain
          >
            <v-card-title class="pb-0">Hand built</v-card-title>
            <div class="mx-4 mb-4 text-left">in the heart of naked city</div>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" :class="$vuetify.breakpoint.lgAndDown ? 'my-auto' : ''">
        <v-card id="newsletter">
          <v-card-subtitle class="pb-0">
            For information on future Ceramic Drops and Shop Updates, please enter your email.
          </v-card-subtitle>
          <v-card-text>
            <form
                v-show="signUpStatsNewsletter === null"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                enctype="application/x-www-form-urlencoded"
                method="post"
                name="newsletter"
                @submit.prevent="signUpNewsletter"
            >
              <input name="form-name" type="hidden" value="newsletter"/>
              <v-alert class="mt-4" dense type="info"><span
                  class="font-weight-bold">The Naked City Ceramics Promise</span><br/>We will never sell / loan / borrow
                your data - it's yours!
              </v-alert>
              <v-text-field
                  v-model="newsletter.email"
                  :rules="[rules.required, rules.email]"
                  label="E-Mail Address"
                  persistent-hint
                  validate-on-blur
              ></v-text-field>
              <v-btn
                  :disabled="signUpStatsNewsletterFailedCount > 2"
                  block
                  class="mt-3"
                  color="orange"
                  dark
                  type="submit"
              >
                Enroll
              </v-btn>
            </form>

            <v-alert v-if="signUpStatsNewsletterFailedCount > 2" type="warning">
              Sign Up Process Disabled. Too many unsuccessful attempts.
            </v-alert>

            <v-alert v-if="signUpStatsNewsletter === false" class="mb-0 mt-4" type="error">
              Pots down! We ran into a slight problem and could not process your last request. Please try again.
            </v-alert>

            <v-alert v-if="signUpStatsNewsletter === true" class="mb-0 mt-4" type="success">
              Thank you so much! We will be in touch soon.
            </v-alert>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <h1 id="about" class="display-2 font-weight-bold my-4">
          About
        </h1>

        <p>
          No Two Alike, A celebration of body positivity embodied through the art of Wabi-Sabi.
        </p>
        <p>
          Wabi-Sabi is a world view centered on the acceptance of transience and impermanence. An aesthetic described as
          one of beauty
          that is "imperfect", impermanent, and incomplete", much like all humans. Wabi-Sabi is a reminder that all of
          us are beautiful in our imperfections,
          and should be accepted and embraced. Despite, flaws, shape, height, width, scars - emotional and physical, we
          remain perfect worthy, and celebrated.
        </p>

        <v-dialog
            v-model="dialog"
            max-width="600px"
            persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                id="contact"
                v-bind="attrs"
                v-on="on"
                block
                class="mt-4"
                color="orange darken-2"
                dark
                outlined
            >
              Special order / Inquiries
            </v-btn>
          </template>
          <form
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              method="POST"
              name="contact"
              @submit.prevent="signUpContact"
          >
            <v-card>
              <v-card-title>
                <v-row align="center" class="my-1">
                  <span class="headline ml-3">Contact Form</span>
                  <v-spacer></v-spacer>
                  <v-btn color="orange" icon @click="toggleContactDialog()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-subtitle>*indicates required field</v-card-subtitle>
              <v-card-text class="pb-0">


                <input name="form-name" type="hidden" value="contact"/>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="contact.name"
                        color="orange"
                        label="Name*"
                        outlined
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="contact.email"
                        color="orange"
                        label="Email*"
                        outlined
                        required
                    ></v-text-field>
                    <v-textarea
                        v-model="contact.message"
                        color="orange"
                        label="Message*"
                        outlined
                        required
                    ></v-textarea>
                    <v-checkbox
                        v-model="contact.newsletter"
                        color="orange"
                        label="Newsletter - Drops and Merch"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-actions>
                <v-btn
                    v-show="signUpStatsContact === null"
                    :disabled="signUpStatsContactFailedCount > 2"
                    block
                    class="mb-4"
                    color="orange"
                    dark
                    type="submit"
                >
                  Send
                </v-btn>

                <v-alert v-if="signUpStatsContactFailedCount > 2" type="warning">
                  Sign Up Process Disabled. Too many unsuccessful attempts.
                </v-alert>

                <v-alert v-if="signUpStatsContact === false" class="mb-0 mt-4" type="error">
                  Pots down! We ran into a slight problem and could not process your last request. Please try again.
                </v-alert>

                <v-alert v-if="signUpStatsContact === true" class="mb-0 mt-4" type="success">
                  Thank you so much! We will be in touch soon.
                </v-alert>

              </v-card-actions>
            </v-card>
          </form>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
        >
          Thank you so much! We will be in touch soon.

          <template v-slot:action="{ attrs }">
            <v-btn
                color="orange"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { EventBus } from '../event-bus';

export default {
  name: 'HelloWorld',
  components: {},
  data: () => ({
    email: null,
    rules: {
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
    dialog: false,
    snackbar: false,
    newsletter: {
      email: null,
    },
    contact: {
      name: null,
      email: null,
      message: null,
      newsletter: false
    },
    signUpStatsNewsletter: null,
    signUpStatsNewsletterFailedCount: 0,
    signUpStatsContact: null,
    signUpStatsContactFailedCount: 0
  }),
  methods: {
    encode(data) {
      return Object.keys(data)
          .map(
              (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
          )
          .join('&');
    },
    signUpNewsletter(e) {
      fetch('/', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: this.encode({
          'form-name': e.target.getAttribute('name'),
          ...this.newsletter,
        }),
      })
          .then(() => {
            this.signUpStatsNewsletter = true
            this.snackbar = true
          })
          .catch(error => {
            this.signUpStatsNewsletter = false
            this.signUpStatsNewsletterFailedCount++
            console.log(error)
          })
    },
    signUpContact() {
      const axiosConfig = {
        header: {'Content-Type': 'application/x-www-form-urlencoded'},
      };
      axios.post(
          '/',
          this.encode({
            'form-name': 'contact',
            ...this.contact,
          }),
          axiosConfig,
          )
          .then(() => {
            this.signUpStatsContact = true
            this.toggleContactDialog()

          })
          .catch(error => {
            this.signUpStatsContact = false
            this.signUpStatsContactFailedCount++
            console.log(error)
          })
    },
    toggleContactDialog() {
      this.dialog = !this.dialog

      if (!this.dialog) {
        this.resetContact()
      }
    },
    resetContact() {
      this.contact.name = null
      this.contact.email = null
      this.contact.message = null
      this.contact.newsletter = null
    }
  },
  mounted (){
    //listening for the custom event
    EventBus.$on('open-form-contact',()=>{
      console.log('heard open contact request')
      this.toggleContactDialog();
    })
  }
}
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 72px);
}

.repeating-gradient {
  background-image: repeating-linear-gradient(-45deg,
  rgba(255, 0, 0, .25),
  rgba(255, 0, 0, .25) 5px,
  rgba(0, 0, 255, .25) 5px,
  rgba(0, 0, 255, .25) 10px
  );
}
</style>
