<template>
  <v-app>
    <v-navigation-drawer app
                         v-model="drawer"
                         fixed
                         temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Naked City Ceramics
          </v-list-item-title>
          <v-list-item-subtitle>
            Custom, Hand Built
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <v-list-item
            link
            color="orange"
            @click="navigateTo('about')"
        >
          <v-list-item-icon>
            <v-icon>mdi-help-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            color="orange"
            @click="navigateTo('newsletter')"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Newsletter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            color="orange"
            @click="openContactForm()"
        >
          <v-list-item-icon>
            <v-icon>mdi-help-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        color="white"
    >
      <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/optimized/logo.jpg')"
          transition="scale-transition"
          width="40"
          @click="drawer = !drawer"

      />
      <v-toolbar-title>NAKED CITY CERAMICS</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
    <template>
      <v-footer padless>
        <v-row no-gutters>
        <v-col
            class="orange lighten-1 white--text text-center"
            cols="12"
        >
          <v-btn
              dark
              text
              href="https://instagram.com/nakedcityceramics"
          >
            <v-icon left>
               mdi-instagram
            </v-icon>
            <span class="caption">Follow us on the gram</span>
          </v-btn>

        </v-col>
        </v-row>
        <v-col
            class="orange white--text text-center"
            cols="12"
        >
          ©{{ new Date().getFullYear() }} — <strong>Naked City Ceramics
        </strong>
        </v-col>

      </v-footer>
    </template>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import { EventBus } from './event-bus.js';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    drawer: null,
    items: [
      { title: 'About', icon: 'mdi-help-box' },
      { title: 'Newsletter', icon: 'mdi-view-dashboard' },
      { title: 'Contact', icon: 'mdi-image' },
    ],
  }),

  methods: {
    navigateTo(location) {
      this.closeDrawer()
      this.$vuetify.goTo(`#${location}`, {})
    },
    openContactForm() {
      this.closeDrawer()
      EventBus.$emit('open-form-contact', {});
    },
    closeDrawer() {
      this.drawer = false
    }
  }
};
</script>
